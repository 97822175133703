import React from "react"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import OfficeMap from "../components/OfficeMap"
import { BASE_BREADCRUMBS } from "../constants"
import "../page-styles/contact.scss"

const ThankYouPage = () => {
  return (
    <Layout
      pageTitle="Contact Us"
      breadcrumbs={[...BASE_BREADCRUMBS, { url: "/contact", name: "Contact" }]}
    >
      <Seo
        title="Contact Us | Typenex Medical"
        description="Have a question about our products or pricing? Click here to get in touch with Typenex Medical Supplies."
      />
      <section id="contact" className="row">
        <div className="col-md-8">
          <h1>THANK YOU!</h1>
          <p>
            Thank you for your interest in Typenex Medical. Rest assured, your information has been received
            and an experienced Typenex Medical representative will respond shortly.
          </p>
        </div>
        <div className="col-md-4">
          <OfficeMap />
        </div>
      </section>
    </Layout>
  )
}

export default ThankYouPage
